import React from 'react';
import s from './Filter.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {FilterType} from '../../types/types';
import {Minus, Plus} from '../../icons/dist';

export enum DataHook {
  Content = 'filter-content',
  ExpandIcon = 'filter-expand-icon',
  FullTitle = 'filter-full-title',
  OptionName = 'filter-option-name',
  Title = 'filter-title',
  TitleWrapper = 'filter-title-wrapper',
}

export interface IFilterProps extends IProvidedGlobalProps {
  children: React.ReactChild;
  expanded: boolean;
  filterType: FilterType;
  title: string;
}

export interface IFilterState {
  expanded: boolean;
  optionName?: string;
}

@withGlobalProps
export class Filter extends React.Component<IFilterProps, IFilterState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
      optionName: '',
    };
  }

  public render() {
    const {expanded, optionName} = this.state;

    return (
      <>
        <button
          onClick={() => this.toggleExpand()}
          className={s.header}
          data-hook={DataHook.TitleWrapper}
          aria-expanded={expanded}>
          <span className={s.title} data-hook={DataHook.FullTitle}>
            <span data-hook={DataHook.Title}>{this.props.title}</span>
            {optionName && <span data-hook={DataHook.OptionName}>: {optionName}</span>}
          </span>
          <span data-hook={DataHook.ExpandIcon} className={s.expandIcon}>
            {expanded ? <Minus /> : <Plus />}
          </span>
        </button>
        {expanded && <div className={s.content}>{this.renderChild()}</div>}
      </>
    );
  }

  private toggleExpand() {
    const nextState = {expanded: !this.state.expanded};
    /* istanbul ignore next: hard to test it */
    this.setState(nextState, () => {
      this.props.globals.updateLayout && this.props.globals.updateLayout();
    });
  }

  private readonly renderChild = () => {
    if (this.props.filterType !== FilterType.COLOR_OPTION) {
      return this.props.children;
    }

    return React.cloneElement(this.props.children as React.ReactElement, {
      showOptionName: optionName => !this.props.globals.isMobile && this.setState({optionName}),
    });
  };
}
