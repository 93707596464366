import React from 'react';
import cx from 'classnames';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {Filter} from '../Filter/Filter';
import {SingleSelectionFilter} from '../SingleSelectionFilter/SingleSelectionFilter';
import {MultipleSelectionFilter} from '../MultipleSelectionFilter/MultipleSelectionFilter';
import {ColorFilter} from '../ColorFilter/ColorFilter';
import {FilterModel, FilterType} from '../../types/types';
import {RangeFilter} from '../RangeFilter/RangeFilter';
import {FiltersHeader} from '../FiltersHeader/FiltersHeader';
import {FiltersSubmitButton} from './FiltersSubmitButton/FiltersSubmitButton';
import {PriceFilterModel} from '../../models/PriceFilterModel';
import s from './Filters.scss';

export interface FiltersProps extends IProvidedGlobalProps {
  clearFilters: Function;
  shouldShowClearFiltersButton: boolean;
  toggleFiltersModalVisibility?: Function;
}

export const enum DataHook {
  ClearX = 'filters-clear-all-x',
}

@withGlobalProps
export class Filters extends React.Component<FiltersProps> {
  private getFilterComponent(filterModel: FilterModel) {
    switch (filterModel.filterType) {
      case FilterType.COLLECTION:
        return (
          <SingleSelectionFilter
            selected={filterModel.activeOptions}
            filterId={filterModel.filterId}
            options={filterModel.options.map(option => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.CUSTOM_COLLECTION:
        return (
          <MultipleSelectionFilter
            filterId={filterModel.filterId}
            selected={[...filterModel.activeOptions]}
            options={filterModel.options.map(option => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.COLOR_OPTION:
        return (
          <ColorFilter
            selected={[...filterModel.activeOptions]}
            filterId={filterModel.filterId}
            colorOptions={filterModel.options.map(option => ({rgbValue: option.rgbValue, name: option.name}))}
          />
        );
      case FilterType.LIST_OPTION:
        return (
          <MultipleSelectionFilter
            selected={[...filterModel.activeOptions, ...filterModel.selectedOptions]}
            filterId={filterModel.filterId}
            options={filterModel.options.map(option => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.PRICE:
        const selectedRange = {
          min: filterModel.activeOptions.minPrice,
          max: filterModel.activeOptions.maxPrice,
        };
        return (
          <RangeFilter filterId={filterModel.filterId} options={filterModel.options} selectedRange={selectedRange} />
        );
    }
  }

  private renderFilterList() {
    const {filterModels} = this.props.globals;

    return (
      <ul className={s.filterList}>
        {filterModels.map((filterModel, index) => (
          <li key={filterModel.filterId}>
            <Filter title={this.filterTitle(filterModel)} filterType={filterModel.filterType} expanded={index === 0}>
              {this.getFilterComponent(filterModel)}
            </Filter>
          </li>
        ))}
      </ul>
    );
  }

  private filterTitle(filterModel: FilterModel) {
    const {
      globals: {isMobile},
    } = this.props;
    let title = filterModel.title;

    if (!isMobile) {
      return title;
    }

    if (Array.isArray(filterModel.activeOptions)) {
      const length = filterModel.activeOptions.length;
      if (length > 0) {
        title += ` (${length})`;
      }
    }

    if (filterModel.activeOptions && (filterModel as PriceFilterModel).activeOptions.minPrice) {
      const filterModelAsPrice = filterModel as PriceFilterModel;
      const minPriceFormatted = filterModelAsPrice.options.find(
        v => v.key === filterModelAsPrice.activeOptions.minPrice
      ).value;
      const maxPriceFormatted = filterModelAsPrice.options.find(
        v => v.key === filterModelAsPrice.activeOptions.maxPrice
      ).value;
      title += ` (${minPriceFormatted} - ${maxPriceFormatted})`;
    }

    return title;
  }

  private clearFiltersButtonClicked() {
    this.props.clearFilters();
  }

  private renderClearFiltersButton() {
    const {
      globals: {hasSelectedFilters, isMobile},
    } = this.props;

    const classNames = cx(s.clearFiltersButton, {[s.disabled]: !hasSelectedFilters});
    const showX = !isMobile;

    return (
      <button data-hook="clear-filters-button" onClick={() => this.clearFiltersButtonClicked()} className={classNames}>
        {this.props.globals.textsMap.clearFiltersButtonText}
        {showX && (
          <span className={s.clearFiltersX} data-hook={DataHook.ClearX}>
            X
          </span>
        )}
      </button>
    );
  }

  public render() {
    const {isMobile} = this.props.globals;
    const {shouldShowClearFiltersButton} = this.props;

    return (
      <section className={s.filters}>
        <FiltersHeader toggleFiltersModalVisibility={this.props.toggleFiltersModalVisibility} />
        {this.renderFilterList()}
        <div className={s.actions}>
          {shouldShowClearFiltersButton && this.renderClearFiltersButton()}
          {isMobile && <FiltersSubmitButton toggleFiltersModalVisibility={this.props.toggleFiltersModalVisibility} />}
        </div>
      </section>
    );
  }
}
