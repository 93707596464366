import React from 'react';
import s from './MobileFiltersModal.scss';
import {Filters} from '../Filters/Filters';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';

export interface IMobileFiltersModal extends IProvidedGlobalProps {
  shouldShowClearFilters: boolean;
  clearFilters: Function;
  closeModal: Function;
}

@withGlobalProps
export class MobileFiltersModal extends React.Component<IMobileFiltersModal> {
  public render() {
    return (
      <>
        <div className={s.mobileFiltersModal} data-hook="mobile-filters-modal">
          <Filters
            toggleFiltersModalVisibility={() => this.props.closeModal(false)}
            shouldShowClearFiltersButton={this.props.shouldShowClearFilters}
            clearFilters={this.props.clearFilters}
          />
        </div>
      </>
    );
  }
}
