import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import s from './FiltersHeader.scss';
import {CloseModal} from '../../icons/dist/components/CloseModal';

export interface IFiltersHeaderProps extends IProvidedGlobalProps {
  toggleFiltersModalVisibility: Function;
}

@withGlobalProps
export class FiltersHeader extends React.Component<IFiltersHeaderProps> {
  public render() {
    const {isMobile} = this.props.globals;

    return (
      <>
        <span className={s.header}>
          <h2 data-hook="filters-title" className={s.title}>
            {this.props.globals.textsMap.filtersTitleText}
          </h2>
          {isMobile && (
            <span
              data-hook="cancel"
              className={s.cancel}
              onClick={() => this.props.toggleFiltersModalVisibility(false)}>
              <CloseModal />
            </span>
          )}
        </span>
      </>
    );
  }
}
